const n = [-2, -1, 0, 1, 2, 3, 4, 5], l = {
  "-2": {
    short: "TBD",
    full: "TBD"
  },
  "-1": {
    short: "PK",
    full: "Pre-kindergarten"
  },
  0: {
    short: "K",
    full: "Kindergarten"
  },
  1: {
    short: "G1",
    full: "Grade 1"
  },
  2: {
    short: "G2",
    full: "Grade 2"
  },
  3: {
    short: "G3",
    full: "Grade 3"
  },
  4: {
    short: "G4",
    full: "Grade 4"
  },
  5: {
    short: "G5",
    full: "Grade 5"
  }
}, e = (o, r = []) => Object.entries(l).filter((s) => !r.includes(parseInt(s[0]))).map(([s, t]) => ({
  id: s,
  text: o === "short" ? t.short : t.full,
  value: parseInt(s)
})).sort(({ value: s }) => s), a = e("short"), d = e("full"), c = e("short", [-2]), m = e("full", [-2]), u = {
  email: "support@happynumbers.com"
}, p = {
  email: "cs@happynumbers.com"
}, h = {
  en: "English",
  es: "Spanish"
}, i = {
  primary: "Placement",
  middle: "Mid-Year",
  finally: "End-of-Year"
}, f = {
  primary: "PT",
  middle: "MOY",
  finally: "EOY"
}, G = {
  far_below: "Far below",
  below: "Below",
  meets: "Meets",
  exceeds: "Exceeds",
  tbd: "TBD"
};
export {
  l as Grades,
  i as LabelsByAssessment,
  G as LabelsByAssessmentResult,
  h as Languages,
  f as ShortLabelsByAssessment,
  p as cs,
  d as dropdownItemsFull,
  m as dropdownItemsNoTBDFull,
  c as dropdownItemsNoTBDShort,
  a as dropdownItemsShort,
  u as support,
  n as values
};
